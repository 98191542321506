import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import PdfGallery from "./PdfPreview";


function StockDeuda() {
  const pdfs = [
    { title: 'Segundo trimestre', url: '/pdfs/2024_stock_deuda_II_trim.pdf' },
    { title: 'Primer trimestre', url: '/pdfs/2024_stock_deuda_I_trim.pdf' },
  ];

  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Stock de deuda</p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          {/* <div className="container texto2-seccion">
          <TituloAnimado extraClass="titulo-secciones2">Título Presupuesto Part.</TituloAnimado>
          <br/><br/>     
          </div > */} {/* ACA VA EL TITULO DE LA SECCION. */}

          <br /><br />

          <div>
            <PdfGallery     
            /*  title="Galería de PDF"      */   
              pdfs={pdfs} // Pasando los datos aquí
            />
          </div>
        </div>

        <Footer4 />
      </div>
    </>
  );
}

export default StockDeuda;
