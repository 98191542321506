import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";





function GobiernoAbierto() {
  return (
    <>
    <div className="seccion2-container">
      <div className="header2-container ">
        <p className="titular text-center "> Gobierno Abierto </p> 
        <Header2 />
      </div>

      <div className="seccion2-wrap">
      <div className="container texto2-seccion">
        <TituloAnimado extraClass="titulo-secciones2">San Isidro transparente. </TituloAnimado>
        <br /><br />
{/* 
        <div className="seccion2-container">
          <img className="imagenSeccion" alt="Circle" src="img/imagenSeccion1.jpg" />
        </div> */}

        <p>
          Desde el Municipio de San Isidro promovemos un Gobierno Abierto, centrado en la transparencia, la rendición de cuentas y la participación ciudadana. Como parte del Plan de Modernización del Municipio, hemos sancionado la Resolución Nº XX/24 que establece el Plan de Gobierno Abierto, con acciones destinadas a consolidar este cambio de paradigma.

        </p>
        <p>
        Impulsamos políticas de apertura fomentando el diseño e implementación de políticas públicas modernas e innovadoras, a partir de acciones conjuntas con la sociedad civil y las distintas áreas de gobierno.

        </p>
        <p>
        La página centralizada de Gobierno Abierto es un primer paso en ese sentido. Ofrece a todos los vecinos acceso a los actos de gobierno, la composición del gobierno municipal, el digesto normativo, un portal de datos abiertos y nuevas herramientas para rendir cuentas sobre la toma de decisión basada en evidencia. De esta manera, hacemos más transparente el gobierno municipal, garantizando la participación y el control ciudadano para mejorar nuestras decisiones.
        </p>
        <p>
        Además, podrán conocer los proyectos en los que estamos trabajando para seguir consolidando esta nueva forma de concebir el Estado. La transparencia y la rendición de cuentas no solo mejoran la gestión pública, sino que también empoderan a los ciudadanos, permitiéndoles contribuir activamente en la construcción de una comunidad más justa, equitativa y plural. 
        </p>
       
      </div >
      </div>
   
      <Footer4 />

      </div>
    </>
  );
}

export default GobiernoAbierto;