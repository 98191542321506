import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import PdfGallery from "./PdfPreview";


function EjecucionPresup() {
  const pdfs = [
    { title: 'Gastos por objeto- 2do trimestre', url: '/pdfs/2024_ii_gastos_por_objeto.pdf' },
    { title: 'Gastos por objeto- 1er trimestre', url: '/pdfs/2024_i_gastos_por_objeto.pdf' },
    { title: 'Gastos Finalidad/Función- 2do trimestre', url: '/pdfs/2024_ii_gastos_por_fyf.pdf' },
    { title: 'Gastos Finalidad/Función- 1er trimestre', url: '/pdfs/2024_i_gastos_por_fyf.pdf' },
    { title: 'Recursos- 2do trimestre', url: '/pdfs/2024_ii_recursos.pdf' },
    { title: 'Recursos- 1er trimestre', url: '/pdfs/2024_i_recursos.pdf' },
  ];

  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Ejecución presupuestaria</p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          {/* <div className="container texto2-seccion">
          <TituloAnimado extraClass="titulo-secciones2">Título Presupuesto Part.</TituloAnimado>
          <br/><br/>     
          </div > */} {/* ACA VA EL TITULO DE LA SECCION. */}

          <br /><br />

          <div>
            <PdfGallery     
            /*  title="Galería de PDF"      */ 
              pdfs={pdfs} // Pasando los datos aquí
            />
          </div>
        </div>

        <Footer4 />
      </div>
    </>
  );
}

export default EjecucionPresup;
