import React from 'react';
import { Link } from 'react-scroll';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import icon1c from '../icons/icon1c.svg';
import icon2c from '../icons/icon2c.svg';
import icon3c from '../icons/icon3c.svg';
import icon4c from '../icons/icon4c.svg';
import icon5c from '../icons/icon5c.svg';
import icon6c from '../icons/icon6c.svg';
import icon7c from '../icons/icon7c.svg';
import icon8c from '../icons/icon8c.svg';





const Home = () => {
  return (
    <div id="home">
      <div className="icons zoom-in ">

        <div className="icon ">
        <div className="inner-square">
          <Link to="seccion1" smooth={true}>
            <img src={icon1c} className="svg-icon" />
            <p>Gobierno Municipal</p>
          </Link>
          </div>
        </div>

        <div className="icon">
        <div className="inner-square">
          <Link to="seccion2" smooth={true}>
            <img src={icon2c} className="svg-icon" />
            <p>Boletín Oficial y Digesto</p>
          </Link>
          </div>
        </div> 

        <div className="icon">
        <div className="inner-square">
          <Link to="seccion3" smooth={true}>
            <img src={icon3c} className="svg-icon" />
            <p>Mapa Digital</p>
          </Link>
          </div> 
        </div>

        <div className="icon">
        <div className="inner-square">
          <Link to="seccion4" smooth={true}>
            <img src={icon4c} className="svg-icon" />
            <p>Datos Abiertos</p>
          </Link>
        </div>
        </div>
        
        <div className="icon">
        <div className="inner-square">
          <Link to="seccion5" smooth={true}>
            <img src={icon5c} className="svg-icon" />
            <p>Datos Económicos</p>
          </Link>
        </div>
        </div>

        <div className="icon">
        <div className="inner-square"> 
          <Link to="seccion6" smooth={true}>
            <img src={icon6c} className="svg-icon" />
            <p>Modernización Administrativa</p>
          </Link>
          </div>
        </div>

        <div className="icon">
        <div className="inner-square">
          <Link to="seccion7" smooth={true}>
            <img src={icon7c} className="svg-icon" />
            <p>Indicadores de Gestión - Informes</p>
          </Link>
        </div>
        </div>
        
        <div className="icon">
        <div className="inner-square">
          <Link to="seccion8" smooth={true}>
            <img src={icon8c} className="svg-icon" />
            <p>Información Pública</p>
          </Link>
        </div>
        </div>

        
      </div>
    </div>
  );
};

export default Home;






