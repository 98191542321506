import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import { Link } from "react-router-dom";

function GobiernoInformes() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Gobierno </p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          <div className="container texto2-seccion">
            {/*    <TituloAnimado extraClass="titulo-secciones2">SubTítulo Informes</TituloAnimado> */}
          </div>

          {/*    BOTONERA */}
          <br />
          <br />

          <div className="button-container">
            <Link to="/en-construccion">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Accesibilidad Municipal
              </button>
            </Link>
          </div>
        </div>

        <Footer4 />
      </div>
    </>
  );
}

export default GobiernoInformes;
