import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";


function ModernizacionAdm() {
  return (
    <>

      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Modernización Administrativa</p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          <div className="container texto2-seccion">
            <TituloAnimado extraClass="titulo-secciones2">¡San Isidro se moderniza! </TituloAnimado>
            <br /><br />

            {/*  <div className="seccion2-container">       
            <img className="imagenSeccion " alt="Circle" src="img/imagenSeccion7.jpg" />
        </div>  */}

            <p>
              Estamos dando un gran salto hacia el futuro con la modernización administrativa como uno de nuestros principales pilares de gestión. La implementación del Sistema de Gestión Documental Electrónica (GDE) busca que los procesos sean más rápidos, ágiles, transparentes y 100% digitales, beneficiando tanto a los vecinos como al personal público. Desde el 29 de enero de 2024 todos los Decretos Municipales y Resoluciones se firman obligatoriamente a través de este Sistema.
            </p>
            <p>
              Además, estamos digitalizando progresivamente todos los servicios administrativos, desde el pago de tasas hasta la habilitación de comercios y el inicio de obras, para que puedas realizar todo desde la comodidad de tu casa o desde donde estés, acercándote la Municipalidad a solo un clic de distancia. Este cambio también tiene un impacto positivo en el ambiente, ya que dejamos de usar papel para los trámites administrativos, reduciendo nuestra huella ecológica.

            </p>
            <p>
              Nuestro próximo gran desafío es la ventanilla única electrónica, con la cual podrás hacer todos tus trámites 100% online y todo en un mismo lugar. Para eso, estamos repensando cada proceso para eliminar pasos innecesarios y agilizar todos tus trámites, haciendo tu vida más sencilla.
            </p>
            <p>
              Todo este esfuerzo tiene un solo objetivo: mejorar tu experiencia como vecino de San Isidro. Queremos que disfrutes de un municipio eficiente, moderno y a tu servicio.
            </p>
            <p>
              Explorá nuestra web para más detalles y descubrí cómo estamos transformando San Isidro en un lugar más conectado y moderno. ¡San Isidro, una ciudad del futuro!
            </p>
            <br /><br />
          </div >
          <div className="button-container">
            <a href="https://tesi.sanisidro.gob.ar" target="_blank" rel="noopener noreferrer">
              <button type="button" className="btn btn-outline-success  btn-lg link-boton ">
                TESI
              </button>
            </a>
          </div >
        </div >

        <Footer4 />
      </div >

    </>
  );
}

export default ModernizacionAdm;