import React, { useState, useRef, useEffect } from 'react';
import Header2 from "../header2";
import Footer4 from "../footer4";




const Organigrama = () => {
  const [expanded, setExpanded] = useState({
    directorA: false,
    directorB: false,
    directorC: false,
    directorD: false,
    directorE: false,
    manager1: false,
    manager2: false,
    manager3: false,
    manager4: false,
    manager5: false,
    manager6: false,
    manager7: false,
    manager8: false,
    manager9: false,
    manager10: false,
    manager11: false,
    manager12: false,
  });


// Estado para controlar la visibilidad del botón de Scroll to Top
const [showScrollTop, setShowScrollTop] = useState(false);


  // Referencia al contenedor desplazable
  const scrollableRef = useRef(null);

  // Función para hacer scroll hacia arriba
  const scrollToTop = () => {
    if (scrollableRef.current) {
      scrollableRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  
  // Escuchar el evento de scroll
  useEffect(() => {
    const handleScroll = () => {
      if (scrollableRef.current.scrollTop > 100) { // 100px Ajusta el valor según sea necesario
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };

    const scrollableElement = scrollableRef.current;
    scrollableElement.addEventListener('scroll', handleScroll);

    return () => {
      scrollableElement.removeEventListener('scroll', handleScroll);
    };
  }, []);



  const toggleExpand = (key) => {
    setExpanded(prevState => ({ ...prevState, [key]: !prevState[key] }));
  };



  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p  className="titular">Organigrama</p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
        <div className="scrollable">
        <div ref={scrollableRef} className="scrollable"> {/* //para el boton scroll top dentro */}
          <div   className="org-container">     
            
              <h1  className="level-1 rectangle caja-texto noPoint">
                <span  className="org-title">Intendente</span>
                <span className="org-name">Ramón Lanus</span>
              </h1>
              <ol className="level-2-wrapper">

                <li >
                  <h2 className="level-2 rectangle caja-texto" onClick={() => toggleExpand('directorA')}>
                    <span className="org-title2">Jefatura de Gabinete</span>
                    <span className="org-name2">Manuela Lopez Menendez</span>
                    {/*    <span className="expand-icon">  {expanded.directorA ? '-' : '+'} </span> */}
                  </h2>
                  <ol className={`level-3-wrapper ${expanded.directorA ? 'expanded' : ''}`}>
                    <li>
                      <h3 className="level-3 rectangle caja-texto" onClick={() => toggleExpand('manager1')}>
                        <span className="org-title3">Secretaría de Salud</span>
                        <span className="org-name3">Angel Yerbara</span>
                        {/*    <span className="expand-icon"> {expanded.manager1 ? '-' : '+'}</span> */}
                        <a href="https://www.sanisidro.gob.ar/municipio/salud" className="info-icon" target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-info-circle info-icon2"></i>
                        </a>
                      </h3>

                      <ol className={`level-4-wrapper ${expanded.manager1 ? 'expanded' : 'managerA'}`}>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Salud</span>
                          <span className="org-name4">Victoria Erdocia</span>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Administración</span>
                          <span className="org-name4">Ignacio Videla</span>
                        </h4></li>
                      </ol>
                      <h3 className="level-3 rectangle caja-texto" onClick={() => toggleExpand('manager2')}>
                        <span className="org-title3">Secretaría de Espacio Público y Ambiente</span>
                        <span className="org-name3">Clara Sanguinetti</span>
                        {/*  <span className="expand-icon"> {expanded.manager2 ? '-' : '+'}</span> */}
                        <a href=" https://www.sanisidro.gob.ar/municipio/espacio-publico" className="info-icon" target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-info-circle info-icon2"></i>
                        </a>
                      </h3>
                      <ol className={`level-4-wrapper ${expanded.manager2 ? 'expanded' : ''}`}>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Espacio Público</span>
                          <span className="org-name4">Francisco Pérez Martínez</span>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Obras Públicas</span>
                          <span className="org-name4">Eugenia Fevre</span>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Movilidad</span>
                          <span className="org-name4">Maximiliano Parisi</span>
                        </h4></li>
                      </ol>
                      <h3 className="level-3 rectangle caja-texto noPoint">
                        <span className="org-title3">Agencia de Recaudación de San Isidro (ARSI)</span>
                        <span className="org-name3">Diego Martín Cortina</span>
                        <a href="https://arsi.gob.ar/" className="info-icon" target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-info-circle info-icon2"></i>
                        </a>
                      </h3>
                      <h3 className="level-3 rectangle caja-texto noPoint">
                        <span className="org-title3">Secretaría de Hacienda y Finanzas</span>
                        <span className="org-name3">Juan Sanguinetti</span>
                        <a href="https://www.sanisidro.gob.ar/economia-y-presupuesto" className="info-icon" target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-info-circle info-icon2"></i>
                        </a>
                      </h3>
                      <h3 className="level-3 rectangle caja-texto" onClick={() => toggleExpand('manager5')}>
                        <span className="org-title3">Secretaría de Gobierno</span>
                        <span className="org-name3">José Sánchez Sorondo</span>
                        {/* <span className="expand-icon"> {expanded.manager5 ? '-' : '+'}</span> */}
                      </h3>
                      <ol className={`level-4-wrapper ${expanded.manager5 ? 'expanded' : ''}`}>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Fiscalización y Control</span>
                          <span className="org-name4">Santiago Pedro Delucchi</span>
                          <a href="https://www.sanisidro.gob.ar/municipio/inspecciones-y-registros-urbanos" className="info-icon" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-info-circle info-icon2"></i>
                          </a>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Relaciones Institucionales</span>
                          <span className="org-name4"></span>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Relaciones con la Comunidad</span>
                          <span className="org-name4">Lucas Prieto Schorr</span>
                        </h4></li>
                      </ol>
                      <h3 className="level-3 rectangle caja-texto" onClick={() => toggleExpand('manager6')}>
                        <span className="org-title3">Secretaría de Desarrollo Humano y Deportes</span>
                        <span className="org-name3">Agustín Algorta</span>
                        {/*     <span className="expand-icon"> {expanded.manager6 ? '-' : '+'}</span> */}
                      </h3>
                      <ol className={`level-4-wrapper ${expanded.manager6 ? 'expanded' : ''}`}>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Niñez, Adolescencia y Comunidad</span>
                          <span className="org-name4">Pilar Giudice</span>
                          <a href="https://www.sanisidro.gob.ar/municipio/ni%C3%B1ez-adolescencia-y-familia" className="info-icon" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-info-circle info-icon2"></i>
                          </a>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Desarrollo Social</span>
                          <span className="org-name4">Maria Emilia Chávez</span>
                          <a href="https://www.sanisidro.gob.ar/municipio/desarrollo-humano-y-social" className="info-icon" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-info-circle info-icon2"></i>
                          </a>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Deportes</span>
                          <span className="org-name4">Federico Pería</span>
                          <a href="https://www.sanisidro.gob.ar/municipio/deporte" className="info-icon" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-info-circle info-icon2"></i>
                          </a>
                        </h4></li>
                      </ol>
                      <h3 className="level-3 rectangle caja-texto" onClick={() => toggleExpand('manager7')}>
                        <span className="org-title3">Secretaría de Educación, Cultura y Trabajo</span>
                        <span className="org-name3">Mercedes Sanguineti</span>
                        {/* <span className="expand-icon"> {expanded.manager7 ? '-' : '+'}</span> */}
                      </h3>
                      <ol className={`level-4-wrapper ${expanded.manager7 ? 'expanded' : ''}`}>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Educación</span>
                          <span className="org-name4">Romina Sandoval</span>
                          <a href="https://www.sanisidro.gob.ar/municipio/educacion" className="info-icon" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-info-circle info-icon2"></i>
                          </a>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Producción y Trabajo</span>
                          <span className="org-name4">Javier Jaureguiberry</span>
                          <a href="https://www.sanisidro.gob.ar/municipio/trabajo-y-produccion" className="info-icon" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-info-circle info-icon2"></i>
                          </a>

                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Cultura</span>
                          <span className="org-name4">Carolina Ruggero</span>
                          <a href=" https://www.sanisidro.gob.ar/municipio/cultura" className="info-icon" target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-info-circle info-icon2"></i>
                          </a>
                        </h4></li>
                      </ol>
                      <h3 className="level-3 rectangle caja-texto noPoint">
                        <span className="org-title3">Secretaría de Seguridad</span>
                        <span className="org-name3">Enrique Rodríguez Varela</span>
                        <a href="https://www.sanisidro.gob.ar/municipio/seguridad " className="info-icon" target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-info-circle info-icon2"></i>
                        </a>
                      </h3>
                      <h3 className="level-3 rectangle caja-texto" onClick={() => toggleExpand('manager9')}>
                        <span className="org-title3">Secretaría de Planeamiento Urbano</span>
                        <span className="org-name3">Florencia Aguilar</span>
                        {/*   <span className="expand-icon"> {expanded.manager9 ? '-' : '+'}</span> */}
                        <a href="https://www.sanisidro.gob.ar/municipio/urbanismo" className="info-icon" target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-info-circle info-icon2"></i>
                        </a>
                      </h3>
                      <ol className={`level-4-wrapper ${expanded.manager9 ? 'expanded' : ''}`}>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Planeamiento Urbano</span>
                          <span className="org-name4">Manuel Abella Nazar</span>
                        </h4></li>
                        <li><h4 className="level-4 rectangle caja-texto noPoint">
                          <span className="org-title4">Subsecretaría de Proyectos Urbanos</span>
                          <span className="org-name4">María Mercedes De La Fuente</span>
                        </h4></li>
                      </ol>
                      <h3 className="level-4 rectangle caja-texto noPoint" >
                        <span className="org-title3">Subsecretaría de Innovación</span>
                        <span className="org-name3">Alejandro Tomas Lucena</span>
                        <a href="https://www.sanisidro.gob.ar/municipio/innovacion" className="info-icon" target="_blank" rel="noopener noreferrer">
                          <i className="bi bi-info-circle info-icon2"></i>
                        </a>
                      </h3>
                      <h3 className="level-4 rectangle caja-texto noPoint" >
                        <span className="org-title3">Subsecretaría de Planificación y Control</span>
                        <span className="org-name3">Gonzalo Cazenave</span>
                      </h3>
                      <h3 className="level-4 rectangle caja-texto noPoint" >
                        <span className="org-title3">Subsecretaría de Recursos Humanos</span>
                        <span className="org-name3">Camila Allegri</span>
                      </h3>
                      <h3 className="level-4 rectangle caja-texto noPoint" >
                        <span className="org-title3">Subsecretaría de Gestión y Participación</span>
                        <span className="org-name3"></span>                       
                      </h3>                         
                                        
                      </li>                         
                  </ol>      
            </li>
          
               


                <li>
                  <h2 className="level-2 rectangle caja-texto" onClick={() => toggleExpand('directorB')}>
                    <span className="org-title2">Secretaría General</span>
                    <span className="org-name2">Santiago J. Dondo</span>
                    {/*    <span className="expand-icon">  {expanded.directorB ? '-' : '+'} </span> */}
                  </h2>
                  <ol className={`level-3-wrapper ${expanded.directorB ? 'expanded' : ''}`}>
                    <li>
                      <h3 className="level-3 rectangle caja-texto noPoint" >
                        <span className="org-title3">Subsecretaría General de la Función Pública</span>
                        <span className="org-name3">Matías Mey</span>

                      </h3>
                      <h3 className="level-3 rectangle caja-texto noPoint" >
                        <span className="org-title3">Subsecretaría de Estrategia y Comunicación</span>
                        <span className="org-name3">Santiago Mayorga</span>

                      </h3>
                    </li>
                  </ol>
                </li>
                <li>
                  <h2 className="level-2 rectangle caja-texto" onClick={() => toggleExpand('directorC')}>
                    <span className="org-title2">Secretaría de Legal y Técnica</span>
                    <span className="org-name2">Laura Carolina Leali</span>
                    {/*    <span className="expand-icon">  {expanded.directorC ? '-' : '+'} </span> */}
                  </h2>
                  <ol className={`level-3-wrapper ${expanded.directorC ? 'expanded' : ''}`}>
                    <li>
                      <h3 className="level-3 rectangle caja-texto noPoint" >
                        <span className="org-title3">Subsecretaría de Legal y Técnica</span>
                        <span className="org-name3">Matías Pérez Alati Brea</span>

                      </h3>
                    </li>
                  </ol>
                </li>
                <li>
                  <ol>
                    <h2 className="level-2 rectangle caja-texto noPoint" >
                      <span className="org-title2">Asesor General</span>
                      <span className="org-name2">Jorge Álvarez Nuñez</span>
                    </h2>
                  </ol>
                </li>
                <li>
                  <h2 className="level-2 rectangle caja-texto noPoint" >
                    <span className="org-title2">Subsecretaría Privada</span>
                    <span className="org-name2">Clara Dondo</span>
                  </h2>
                </li>               
              </ol>         
            </div>
          </div>    

          {showScrollTop && (
          <button onClick={scrollToTop} className="scroll-to-top-btn">
          <i className="bi bi-chevron-double-up"></i>
          </button>         
           )}

         </div>  
        </div>
        <Footer4 />
      </div>
    </>
  );
};

export default Organigrama;


