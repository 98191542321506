import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import PdfGallery from "./PdfPreview";


function Presupuestos() {
  const pdfs = [
    { title: 'Presupuesto', url: '/pdfs/2024_presupuesto_hcd.pdf' },
    { title: 'Cálculo de recursos-1er semestre', url: '/pdfs/2024_presupuesto_de_recursos_y_gastos_por_rubro_y_objeto.pdf' },
  ];

  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Presupuestos </p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          {/* <div className="container texto2-seccion">
          <TituloAnimado extraClass="titulo-secciones2">Título Presupuesto Part.</TituloAnimado>
          <br/><br/>     
          </div > */} {/* ACA VA EL TITULO DE LA SECCION. */}

          <br /><br />

          <div>
            <PdfGallery             
              pdfs={pdfs} // Pasando los datos aquí
            />
          </div>
        </div>

        <Footer4 />
      </div>
    </>
  );
}

export default Presupuestos;
