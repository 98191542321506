import React, { useState, useEffect } from "react";
import MatrixRain from "./MatrixRain";


const TextAnimation = () => {
  const targetText = "En construcción!";
  const [displayText, setDisplayText] = useState(Array(targetText.length).fill(""));
  const [currentIndex, setCurrentIndex] = useState(0);

  // Función para generar una letra minúscula aleatoria
  const getRandomLowercaseLetter = () => {
    const letters = 'abcdefghijklmnopqrstuvwxyz';
    return letters.charAt(Math.floor(Math.random() * letters.length));
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setDisplayText((prev) =>
        prev.map((char, index) => {
          if (index < currentIndex) return targetText[index];
          return getRandomLowercaseLetter(); // Genera solo letras minúsculas aleatorias
        })
      );
    }, 30); // Reducido para aumentar la velocidad del efecto aleatorio

    return () => clearInterval(interval);
  }, [currentIndex]);

  useEffect(() => {
    if (currentIndex < targetText.length) {
      const timeout = setTimeout(() => {
        setCurrentIndex((prev) => prev + 1);
      }, 140);

      return () => clearTimeout(timeout);
    } else {
      setDisplayText(targetText.split(""));
    }
  }, [currentIndex]);

  return (
    <div className="container-construct">
      <MatrixRain /> {/* Aquí se integra la lluvia tipo Matrix */}
      <div className="background"></div>
      <div className="text-construct">{displayText.join("")}</div>
    </div>
  );
};

export default TextAnimation;
