import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import PdfGallery from "./PdfPreview";


function SitEcoFin() {
  const pdfs = [
    { title: 'SEF II', url: '/pdfs/sefII.pdf' },
    { title: 'SEF I', url: '/pdfs/sefI.pdf' },
  ];

  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Situación Económica-Financiera </p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          {/* <div className="container texto2-seccion">
          <TituloAnimado extraClass="titulo-secciones2">Título Presupuesto Part.</TituloAnimado>
          <br/><br/>     
          </div > */} {/* ACA VA EL TITULO DE LA SECCION. */}

          <br /><br />

          <div>
            <PdfGallery             
              pdfs={pdfs} // Pasando los datos aquí
            />
          </div>
        </div>

        <Footer4 />
      </div>
    </>
  );
}

export default SitEcoFin;
