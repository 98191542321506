import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import LoadingText from "./TextAnimation";
import icon9c from "../../icons/icon9c.svg";
import { Link } from "react-router-dom";

function Informes() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Informes </p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          <div className="container texto2-seccion">
            {/*    <TituloAnimado extraClass="titulo-secciones2">SubTítulo Informes</TituloAnimado> */}
            <br />
            <p>
              Desde la Dirección General de Datos de la Subsecretaría de
              Innovación elaboramos informes, en conjunto con las áreas
              municipales, que ayudan a las tareas de planificación, monitoreo y
              evaluación de políticas públicas. Acá podrás encontrar los
              distintos informes elaborados. Los mismos se encuentran divididos
              por cada área temática del gobierno.
            </p>

            <br />
            <br />
          </div>

          {/*    BOTONERA */}

          <div className="button-container">
            <Link to="/cultura">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Cultura
              </button>
            </Link>
            <Link to="/gob-informes">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Gobierno
              </button>
            </Link>
            <Link to="/espacio-publico">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Espacio Público
              </button>
            </Link>
          </div>
        </div>
        <Footer4 />
      </div>
    </>
  );
}

export default Informes;
