import React, { useState } from 'react';
import { Link } from "react-router-dom"
import TituloAnimado from './tituloAnimado';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css"; 


/*NAV AND HEADER*/

const Header = () => {

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  /* 
  ESTO ES PARA HACER CORROUSEL EN PORTADA:*/
   const settings = {
   dots: false,
   infinite: true,
   speed: 500,
   slidesToShow: 1,
   slidesToScroll: 1,
   autoplay: true,
   autoplaySpeed: 3500,
   fade: true
 };

 const images = [
   "img/parque.jpg" ,
   "img/imagenSeccion9.jpg",
   "img/header1.jpg",
   "img/imagenSeccion1.jpg",
   "img/header5.jpg",
   "img/niños.jpg",
  
   
  
  
 ];  
 


  return (
   <> 
      <nav>
        <div className="nav-content">
          <div className="logo ">
            <img src="img/nuevoLogoBlanco.png" alt="San Isidro Logo" className="logo-nav " />
            <span > 
              <span className="description1"> | &nbsp; Gobierno</span>
              <span className="description2"> Abierto</span>
            </span>
          </div>

          <div className="menu-icon" onClick={toggleMenu}>
            &#9776;
          </div>


          <ul className={isOpen ? "nav-links open" : "nav-links"}>
            <li><Link to="/" className='seccion-home'>INICIO</Link></li>
            <li><Link to="/gob-ar" className='seccion-home'>GOBIERNO ABIERTO</Link></li>
            <li><Link to="/portal-obras" className='seccion-home'>PORTAL DE OBRAS</Link></li>
            <li><Link to="/LAB" className='seccion-home'>LAB</Link></li>
            <li><Link to="/presupuesto-participativo" className='seccion-home'>PRESUPUESTO PARTICIPATIVO</Link></li>
            {/*  <li><a href="#" target="_blank" rel="noopener noreferrer">Gob.Ar.</a></li>  Preparo para ir hacia otras paginas */}
          </ul>
        </div>
      </nav>

       <div id="header" className="header-bg">

        <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} className="header-image" />
            </div>
          ))}
        </Slider>  
{/* 
       <img src="img/parque.jpg" alt="header1" className="header-image  " />   */}
      
        <div className="header-content">
          <div className="header-logo-container zoom-in">
            <img src="img/nuevoLogoBlanco.png" alt="Municipio de San Isidro Logo" className="header-logo zoom-in " />
            <p className="subtitle zoom-in">

              
              <TituloAnimado><span className="subtitle1"> Gobierno</span>
                <span className="subtitle2"> Abierto </span></TituloAnimado>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;


       {/*  <Slider {...settings}>
          {images.map((image, index) => (
            <div key={index}>
              <img src={image} alt={`Slide ${index}`} className="header-image" />
            </div>
          ))}
        </Slider>   */}