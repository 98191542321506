import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import LoadingText from "./TextAnimation";
import icon9c from "../../icons/icon9c.svg";
import { Link } from "react-router-dom";

function EspacioPublico() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Espacio público </p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          <div className="container texto2-seccion">
            {/*    <TituloAnimado extraClass="titulo-secciones2">xxxxxx</TituloAnimado> */}
          </div>

          {/*    BOTONERA */}
          <br />
          <br />

          <div className="button-container">
            {/* <a
              href="https://lookerstudio.google.com/reporting/c05e5824-4905-4f2d-990e-5c5f17be2839/page/tEnnC"
              target="_blank"
              rel="noopener noreferrer"
            > */}
            <Link to="/licencias">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Licencias
              </button>
            </Link>
            {/* </a> */}
          </div>
        </div>
        <Footer4 />
      </div>
    </>
  );
}

export default EspacioPublico;
