import React, { useState } from 'react';
import { Link } from "react-router-dom"
import TituloAnimado from './tituloAnimado';



/*NAV AND HEADER*/

const Header2 = ({ height = "280px" }) => { /* le coloco prop para poder luego cambiarle el hight, sin cambiar todo */

    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };




    return (
        <>
            <nav>
                <div className="nav-content">
                    <div className="logo ">
                        <Link to="/">
                            <img src="img/nuevoLogoBlanco.png" alt="San Isidro Logo" className="logo-nav " />
                            </Link>

                            <Link to="/" className="description">
                            <span>
                                <span className="description1"> | &nbsp; Gobierno</span>
                                <span className="description2"> Abierto</span>
                            </span>
                            </Link>
                    </div>

                    <div className="menu-icon" onClick={toggleMenu}>
                        &#9776;
                    </div>


                    <ul className={isOpen ? "nav-links open" : "nav-links"}>
                        <li><Link to="/" className='seccion-home'>INICIO</Link></li>
                        <li><Link to="/gob-ar" className='seccion-home'>GOBIERNO ABIERTO</Link></li>
                        <li><Link to="/portal-obras" className='seccion-home'>PORTAL DE OBRAS</Link></li>
                        <li><Link to="/LAB" className='seccion-home'>LAB</Link></li>
                        <li><Link to="/presupuesto-participativo" className='seccion-home'>PRESUPUESTO PARTICIPATIVO</Link></li>
                        {/*  <li><a href="#" target="_blank" rel="noopener noreferrer">Gob.Ar.</a></li>  Preparo para ir hacia otras paginas */}
                    </ul>
                </div>
            </nav>

            <div id="header2" >
                <img src="img/header2.jpeg" alt="header2" className="header2-bg" 
                 style={{ height: height, width: "100%", objectFit: "cover" }}/>

            </div>



        </>




    );
};

export default Header2;


