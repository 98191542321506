import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import LoadingText from "./TextAnimation";
import icon9c from '../../icons/icon9c.svg';


function MapaDigital() {
  return (
    <>
      <div className="header2-container">
        <p className="titular"> Mapa Digital </p>
        <Header2 />
      </div>


      {/*     <div className="container texto2-seccion">
      <TituloAnimado extraClass="titulo-secciones2">Título Mapa Digital.</TituloAnimado>
      <h2>ACA VA EL TEXTO del articulo LUEGO, DENTRODE LOS DIVS</h2>
    </div>  */}


      {/*   <div className="seccion2-container">       
            <img className="imagenSeccion " alt="Circle" src="img/imagenSeccion6.jpg" />
        </div>  */}


      <br/><br/>
      <div className="outer-container shadow-lg">
        <div className="container-construct">
          <LoadingText />
          <img src={icon9c} className="icon-construc" alt="Icon" />
        </div>
      </div>
      <br/><br/>


      <Footer4 />

    </>
  );
}

export default MapaDigital;