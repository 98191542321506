import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";

function ExAnte() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container " >
          <p className="titular-html"> Vacaciones de Invierno - Ex Ante </p>
          <Header2 height="200px" />
        </div>

        {/* IFRAME */}
        <div className="iframe-container">
          <iframe
            src="/statics/informe-invierno-2024-exante.html" // Ruta relativa al archivo HTML
            width="100%"
            height="700px"
            title="Informe Ex Ante"
            style={{ border: "2px solid white" }} 
          ></iframe>
        </div>
        
        <Footer4 />
      </div>
    </>
  );
}

export default ExAnte;
