import React, { useEffect, useRef } from 'react';

function TituloAnimado({ children, extraClass }) {
  const titleRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('animate-line');
        } else {
          entry.target.classList.remove('animate-line');
        }
      },
      { threshold: 0.1 }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <h2 className={`titulo-secciones ${extraClass}`} ref={titleRef}>
      {children}
      <span className="underline"></span>
    </h2>
  );
}

export default TituloAnimado;
