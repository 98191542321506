import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import icon9c from '../../icons/icon9c.svg';
import { Link } from "react-router-dom";


function GobiernoMunicipal() {
    return (
        <>
        
        <div className="seccion2-container">
            <div className="header2-container">
                <p className="titular"> Gobierno Municipal </p>
                <Header2 />
            </div>

            <div className="seccion2-wrap">
            <div className="container texto2-seccion">
                <TituloAnimado extraClass="titulo-secciones2">Título Gobierno Municipal.</TituloAnimado>

                <br /><br />
                <p>En este apartado podrás encontrar información sobre cómo se compone el gobierno municipal, los funcionarios designados en cada una de las áreas e información de las competencias de cada una de ellas. Queremos que los vecinos conozcan qué hace cada una de nuestras áreas técnicas y cómo contactarlas. Un Gobierno más transparente lo hacemos entre todos.</p>

            </div>
            <br/><br/><br/>


            <div className="button-container">
                <Link to="/organigrama" > 
                    <button type="button" className="btn btn-outline-success  btn-lg link-boton">
                        Organigrama
                    </button>
                </Link>
                <a href="https://www.sanisidro.gob.ar/miMunicipio" target="_blank" rel="noopener noreferrer">
                    <button type="button" className="btn btn-outline-success  btn-lg link-boton">
                        Mi Municipio
                    </button>
                </a>
            </div>
            </div>

        

            <Footer4 />

</div>
        </>
    );
}

export default GobiernoMunicipal;