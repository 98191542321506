import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import { Link } from "react-router-dom";

function GestionInformes() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular"> Indicadores de Gestión - Informes </p>
          <Header2 />
        </div>

        <div className="seccion2-wrap">
          <div className="container texto2-seccion">
            <TituloAnimado extraClass="titulo-secciones2">
              Políticas públicas basadas en evidencia.
            </TituloAnimado>
            <br />
            <br />

            {/*  <div className="seccion2-container">       
            <img className="imagenSeccion" alt="Circle" src="img/imagenSeccion9.jpg" />
        </div>   */}

            <p>
              El Municipio de San Isidro está desarrollando un cambio de
              paradigma en la toma de decisiones basadas en evidencia. Es por
              eso que se elaboró un documento que establece las Prioridades
              Estratégicas de la gestión, y con ello la determinación de los
              indicadores estratégicos de procesos y resultados con el fin de
              monitorear y evaluar la gestión de manera diaria.
            </p>
            <p>
              En este apartado podrás encontrar información sobre cómo el
              Municipio planifica, monitorea y evalúa las políticas públicas que
              se implementaron o implementarán en el futuro. Los informes de
              gestión permiten el análisis ex-ante y ex post de cada política a
              ejecutar para ayudar a las áreas técnicas a tomar decisiones y
              reproyectar políticas que han tenido puntos débiles en su
              implementación. El cruce de datos de manera integral permite a
              nuestro Municipio ser más eficiente en la toma de decisiones y
              reducir costos en el desarrollo de políticas públicas.
            </p>
            <p>
              La apertura de este tipo de información permite a la ciudadanía
              controlar y entender las decisiones de los funcionarios públicos,
              garantizando la participación ciudadana en la toma de decisiones.
            </p>

            <br />
            <br />
          </div>
          <div className="button-container">
            <a
              href="/pdfs/prioridades_estrategicas_2024-2025.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Prioridades Estratégicas
              </button>
            </a>
            <Link to="/indicadores-gestion">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Indicadores de Gestión
              </button>
            </Link>
            <Link to="/informes">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Informes
              </button>
            </Link>
            <Link to="/datos-guias">
              <button
                type="button"
                className="btn btn-outline-success  btn-lg link-boton"
              >
                Gobernanza de Datos
              </button>
            </Link>
          </div>
        </div>

        <Footer4 />
      </div>
    </>
  );
}

export default GestionInformes;
