import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import { Link } from "react-router-dom";


function GobernanzaDatos() {
    return (
        <div className="seccion2-container">
            <div className="header2-container">
                <p className="titular"> Cómo trabajamos nuestros datos </p>
                <Header2 />
            </div>

            <div className="seccion2-wrap">
                <div className="container texto2-seccion">
                   {/*  <TituloAnimado extraClass="titulo-secciones2">Subtítulo Datos-Eco.</TituloAnimado> */}
                    <br />
                    {/* Contenido de la página */}
                    <p>En el Municipio de San Isidro elaboramos una estrategia de gobernanza de datos para llevar una administración eficiente de los datos producidos estableciendo parámetros para su mejora continua de calidad y la valorización en su utilización. Esta estrategia se basa en el reconocimiento de los conjuntos de datos que posee el Municipio para catalogarlos, generar sus metadatos correspondientes y aplicar criterios de mejora de calidad para alcanzar intentar alcanzar los criterios óptimos.</p>
                    <p>En ese sentido, se ha elaborado un inventario de datos que permite reconocer los conjuntos de datos más relevantes que trabaja el municipio, su frecuencia de actualización y otra información importante al respecto. Por otro lado, se han confeccionado guías que establecen los roles dentro de la gobernanza de datos, los criterios para su normalización, estandarización y mejora de calidad de los mismos, guías de anonimización de conjuntos de datos que contengan información personal o sensible, y protocolos de intercambio de información interna y externa. Todas estas guías podrás encontrarlas aquí abajo.</p>
                  
                    <br /><br />

                    <div className="button-container titulo-secciones2">

                    <a href="/pdfs/guia-de-roles-de-gobernanza-de-datos.pdf" target="_blank" rel="noopener noreferrer">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton3 ">
                             Roles dentro de la gobernanza de datos
                            </button>
                        </a>

                        <a href="/pdfs/guia_estandarizacion_datos.pdf" target="_blank" rel="noopener noreferrer">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton3 ">
                            Normalización, estandarización y calidad de los datos
                            </button>
                        </a>

                        <Link to="/en-construccion">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton3 ">
                            Protocolos de intercambio de información
                            </button>
                        </Link>

                        <Link to="/en-construccion">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton3 ">
                            Anonimización de datos personales
                            </button>
                        </Link>

                        <Link to="/en-construccion">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton3  ">
                            Inventario de datos
                            </button>
                        </Link>

                    </div>
                </div>
            </div>
            <Footer4 />
        </div>
    );
}

export default GobernanzaDatos;
