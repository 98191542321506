import React from 'react';
import MapComponent from './map';

function Footer3() {
    return (
        <>

            <div className="container-fluid">
                <footer className="py-3 my-4">

                    <ul className="nav justify-content-center border-bottom pb-3 mb-3">

                        <li className="nav-item img-fluid "><div><img src="img/nuevoLogoVerdeB.png" className='logo-footer3 img-fluid' alt="San Isidro Logo" />
                        </div> </li>

                        <li className="nav-item titulo-footer"><p className="nav-link px-2 text-body-light ">Municipalidad de San Isidro </p></li>
                        <li className="nav-item"><p href="#" className="nav-link px-2 text-body-secondary">  | Subsecretaría de Innovación |</p></li>

                    </ul>

                    <p className="text-center text-body-light"><span className=" direc bi bi-geo-alt-fill">  Av. Centenario 77, San Isidro, Provincia de Buenos Aires. </span> </p>
                    <p className="text-center text-body-light"><span className=" email bi bi-envelope-at-fill"> innovacion@sanisidro.gob.ar </span>  </p>

                    <div className="map-container d-flex justify-content-center my-4">
                        <div className="map-box">
                            <MapComponent />
                        </div>
                    </div>
                </footer>
            </div>

        </>
    );
}

export default Footer3;