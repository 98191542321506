import React, { useState } from 'react';
import { Card, Button, Modal } from 'react-bootstrap';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { pdfjs } from 'react-pdf';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

// Componente para la vista previa del PDF
const PDFPreview = ({ pdfUrl, show, handleClose }) => (
  <Modal show={show} onHide={handleClose} size="lg" centered>
    <Modal.Header >
      <Modal.Title>Vista previa</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
        <Viewer fileUrl={pdfUrl} />
      </Worker>
    </Modal.Body>
    <Modal.Footer>
      <Button variant="btn btn-dark" onClick={handleClose}>
        Cerrar
      </Button>
      <Button variant="btn btn-dark" href={pdfUrl} download>
        Descargar PDF
      </Button>
    </Modal.Footer>
  </Modal>
);

// Componente para cada tarjeta de PDF
const PdfCard = ({ pdf }) => {
  const [showViewer, setShowViewer] = useState(false);

  const handleView = () => setShowViewer(true);
  const handleClose = () => setShowViewer(false);

  return (
    <>
      <Card className="pdf-container">
        <Card.Body className="cardBody">
          <div className="pdf-viewer">
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}>
              <Viewer fileUrl={pdf.url} />
            </Worker>
          </div>
          <Card.Title className='pdfTitulo'>{pdf.title}</Card.Title>
          <div className='button-container2'>
            <Button className="link-boton2" onClick={handleView}>
              Vista previa
            </Button>
            <Button className="link-boton2" href={pdf.url} download style={{ marginLeft: '10px' }}>
              Descargar pdf
            </Button>
          </div>
        </Card.Body>
      </Card>

      <PDFPreview pdfUrl={pdf.url} show={showViewer} handleClose={handleClose} />
    </>
  );
};

// Componente de la galería de PDFs que recibe `title` y `pdfs` como props
const PdfGallery = ({ title, pdfs }) => {
  return (
    <div>
      <h2>{title}</h2> {/* Muestra el título recibido como prop */}
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
        {pdfs.map((pdf, index) => (
          <PdfCard key={index} pdf={pdf} /> /* Pasa cada PDF como prop a PdfCard */
        ))}
      </div>
    </div>
  );
};

export default PdfGallery;
