import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import LoadingText from "./TextAnimation";
import icon9c from '../../icons/icon9c.svg';


function PortalObras() {
  return (
    <>
    <div className="seccion2-container">
      <div className="header2-container">
        <p className="titular"> Portal de Obras </p>
        <Header2 />
      </div>

      <div className="seccion2-wrap">
      {/* <div className="container texto2-seccion">
        <TituloAnimado extraClass="titulo-secciones2">Título Portal de obras.</TituloAnimado>
        </div> */}


      {/*  <div className="seccion2-container">
          <img className="imagenSeccion " alt="Circle" src="img/imagenSeccion2.jpg" />
        </div> */}

      <br /><br />
      <div className="outer-container shadow-lg">
        <div className="container-construct">
          <LoadingText />
          <img src={icon9c} className="icon-construc" alt="Icon" />
        </div>
      </div>
      </div>

      <Footer4 />

      </div>
    </>
  );
}

export default PortalObras;