import React from 'react';
import TituloAnimado from '../tituloAnimado';
import { Link } from "react-router-dom";

/*ACA ESTAN TODAS LAS SECCIONES JUNTAS*/


function SeccionesGral() {
    return (
        <>


            <section id="seccion1" className="container seccion ">
                <div className=" row featurette  py-5 seccion-content seccion1">
                    <div className="col-lg-6 order-lg-1">
                        <TituloAnimado>Gobierno Municipal.</TituloAnimado>
                        <br />   <br />
                        <p className="texto-seccion text-sm-left ">
                            Desde el Municipio de San Isidro pregonamos la transparencia y la rendición de cuentas permanente.
                            En este apartado encontrarás el Organigrama Municipal, la descripción de tareas de cada área, sus objetivos, noticias y formatos de contacto.
                            <Link to="/gob-municipal" className=" link-color"> Ver más</Link>.
                        </p>
                    </div>
                    <div className="col-lg-6 order-lg-2">
                        <img
                            src="img/gob_muni.jpg"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                             width="800"
                            height="800"
                            alt="Placeholder: 500x500"
                        />
                    </div>
                </div>
                <div className="separador" />
            </section>



            <section id="seccion2" className=" container  seccion ">
                <div className="row featurette  py-5 seccion-content seccion2">
                    <div className="col-lg-6 order-lg-2 ">
                        <TituloAnimado> Boletín Oficial y Digesto.</TituloAnimado>
                        <br /> <br />
                        <p className="texto-seccion">
                            Aquí encontrarás los actos administrativos del Municipio de San Isidro, los cuales se muestran por su fecha de publicación.
                            Todos los actos administrativos publicados desde la segunda quincena de marzo de 2024 se encuentran en un nuevo
                            formato más accesible y cómodo para la búsqueda. Si buscas algo anterior deberás acceder a este
                            <a href="https://boletines.sanisidro.gob.ar/index.php/boletin" target="_blank" className=" link-color"> portal</a>.
                            <br />
                            También encontrarás el Digesto, que es la herramienta en donde se unifican las normas de carácter general vigentes en el Municipio
                            (tanto Ordenanzas como Decretos). ¡Cuenta con un nuevo formato mucho más accesible!
                        </p>
                        <br />
                        <div className="button-container">
                            <a href="https://www.sanisidro.gob.ar/boletin" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-outline-success  btn-lg link-boton">
                                    Boletín Oficial
                                </button>
                            </a>
                            <a href="https://boletines.sanisidro.gob.ar/index.php/digesto" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-outline-success  btn-lg link-boton">
                                    Digesto
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-6 order-lg-1 ">
                        <img
                            src="img/BOyDigestC.jpg"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Placeholder: 500x500"
                        />
                    </div>
                </div>

                <div className="separador" />
            </section>


            <section id="seccion3" className=" container  seccion ">
                <div className="row featurette  py-5 seccion-content seccion3">
                    <div className="col-lg-6 ">
                        <TituloAnimado>Mapa Digital.</TituloAnimado>
                        <br /> <br />
                        <p className="texto-seccion">
                            En San Isidro desarrollamos nuestra propia Infraestructura de Datos Espaciales (IDE).
                            Esta herramienta nos permite un trabajo articulado entre las distintas áreas en lo correspondiente a datos georreferenciados,
                            y además le garantizamos a los vecinos un mapa interactivo con la posibilidad de visualizar distintas capas interactivas.
                            ¡Entrá {/* <Link to="/mapa-digital" className=" link-color"> acá</Link>!. */}
                            <span>
                                <Link to="/en-construccion" className="link-color"> acá</Link>!.
                            </span>
                        </p>

                        <br />
                    </div>
                    {/* <div className="col-md-5">
                        <img
                            src="img/mapaDigital.jpg"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Placeholder: 500x500"
                        />
                    </div> */}
                    <div className="col-lg-6">
                        <video
                            src="img/mapaDigitalMedia1.mp4"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Mapa Digital"
                            loop
                            autoPlay
                            muted
                        />
                    </div>
                </div>

                <div className="separador" />
                {/* Contenido del separador */}
            </section>


            <section id="seccion4" className="container seccion ">
                <div className="row featurette  py-5 seccion-content seccion4">
                    <div className=" col-lg-6 order-lg-2 ">
                        <TituloAnimado>Datos Abiertos.</TituloAnimado>
                        <br /> <br />
                        <p className="texto-seccion">
                            La apertura de datos es una política primordial que hace a la transparencia y a la rendición de cuentas.
                            En este apartado encontrarás el repositorio de datos en formato abierto, podés descargar distintos <em> datasets</em>  para visualizar y
                            trabajar con los datos abiertos municipales. ¡No dudes en compartirnos la información que trabajes con nuestros datos para hacer
                            publicidad de ellos!
                        </p>
                        <br />
                        <div className="button-container">
                            <a href="http://datos.sanisidro.gob.ar/" target="_blank" rel="noopener noreferrer">
                                <button type="button" className="btn btn-outline-success  btn-lg link-boton">
                                   Accedé al Portal
                                </button>
                            </a>
                        </div>
                    </div>

                    <div className="col-lg-6 order-lg-1">
                        <video
                            src="img/datos_abiertos.mp4"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Datos Abiertos"
                            loop
                            autoPlay
                            muted
                        />
                    </div>
                </div>

                <div className="separador" />

            </section>

            <section id="seccion5" className=" container  seccion ">
                <div className="row featurette  py-5 seccion-content seccion5">
                    <div className="col-lg-6 ">
                        <TituloAnimado>Datos Económicos.</TituloAnimado>

                        <br /> <br />
                        <p className="texto-seccion">
                            Brindamos información accesible sobre los cálculos de gastos y recursos
                            aprobados por el Concejo Deliberante para que puedas conocer cómo se
                            recauda y gasta el dinero del Municipio.
                            <Link to="/datos-economicos" className=" link-color"> Ver más</Link>.
                        </p>
                        <br />

                    </div>
                    <div className="col-lg-6">
                        <img
                            src="img/datosAbiertos.jpeg"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Placeholder: 500x500"
                        />
                    </div>
                </div>

                <div className="separador mt-4 mb-3" />
                {/* Contenido del separador */}

            </section>

            <section id="seccion6" className=" container seccion ">
                <div className="row featurette  py-5 seccion-content seccion6">
                    <div className="col-lg-6 order-lg-2 ">
                        <TituloAnimado>Modernización Administrativa.</TituloAnimado>

                        <br /><br />

                        <p className="texto-seccion">
                            Uno de los pilares fundamentales de nuestra gestión es la modernización administrativa, la cual se construye en base a la digitalización, la simplificación de los procesos y la eficientización de los recursos. Para ello estamos implementando el Sistema de Gestión Documental Electrónica (GDE) para que los expedientes, informes y actos administrativos sean más rápidos,  transparentes y 100% digitales.
                            <br />
                            Estamos digitalizando progresivamente todos los trámites administrativos para que no tengas que acercarte para pagar una tasa, habilitar un comercio o comenzar una obra. De esta manera, optimizamos los procesos y quitamos tasas innecesarias que sólo cargan tributariamente al vecino. ¡Queremos que toda la municipalidad esté al alcance de un click!.                        
                            <Link to="/modernizacion-adm" className=" link-color"> Ver más.</Link>
                        </p>

                        <br />
                    </div>
                    <div className="col-lg-6 order-lg-1">
                        <img
                            src="img/adm.jpg"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Placeholder: 500x500"
                        />
                    </div>
                </div>

                <div className="separador" />
            </section>

            <section id="seccion7" className=" container seccion ">
                <div className="row featurette  py-5 seccion-content seccion7">
                    <div className="col-lg-6 ">
                        <TituloAnimado>Indicadores de Gestión | Informes.</TituloAnimado>

                        <br /> <br />
                        <p className="texto-seccion">En el Municipio de San Isidro planificamos y tomamos decisiones basadas
                            en los datos y la evidencia producida. Es por eso que en este apartado podrás
                            encontrar los indicadores estratégicos con los que medimos y monitoreamos nuestra
                            gestión. También podrás encontrar los distintos informes elaborados por la Dirección
                            General de Datos que analizan o evalúan la factibilidad en el desarrollo de las políticas
                            públicas del Municipio.
                            <Link to="/gestion-informes" className=" link-color"> Ver más.</Link> </p>

                        <br />
                    </div>
                    <div className="col-lg-6">
                        <img
                            src="img/indic_gestion.jpg"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Placeholder: 500x500"
                        />
                    </div>
                </div>

                <div className="separador" />
                {/* Contenido del separador */}


            </section>

            <section id="seccion8" className=" container seccion ">
                <div className="row featurette  py-5  seccion-content seccion8">
                    <div className="col-lg-6 order-lg-2 ">
                        <TituloAnimado>Información Pública.</TituloAnimado>


                        <br /> <br />
                        <p className="texto-seccion">Trabajamos diariamente para acercar la gestión al vecino, mejorando la transparencia y los canales de acceso para rendir cuenta a nuestros vecinos. Habilitamos un formulario digital para que puedas acceder a la información pública del municipio. <br />
                            Elaboramos y publicamos el índice de transparencia activa, el cual muestra el nivel de cumplimiento de las dimensiones que deben publicarse por la normativa vigente.  <Link to="/informacion-publica" className="link-color">Ver más</Link>
                        </p>



                        <br />
                    </div>
                    <div className="col-lg-6 order-lg-1">
                        <img
                            src="img/info_publica2.jpg"
                            className="bd-placeholder-img bd-placeholder-img-lg featurette-image img-fluid mx-auto"
                            width="800"
                            height="800"
                            alt="Placeholder: 500x500"
                        />
                    </div>
                </div>

                <div className="separador separador8" />
                <br /> <br /> <br /><br />
            </section>






        </>
    );
}

export default SeccionesGral;