import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";

function Licencias() {
  return (
    <>
      <div className="seccion2-container">
        <div className="header2-container">
          <p className="titular-html"> Licencias </p>
          <Header2 height="200px" />
        </div>

        {/* IFRAME */}
        <div
          className="iframe-container"
          style={{ width: "100%", height: "700px", overflow: "hidden" }}
        >
          <iframe
            width="100%"
            height="100%"
            src="https://lookerstudio.google.com/embed/reporting/c05e5824-4905-4f2d-990e-5c5f17be2839/page/tEnnC"
            style={{ border: "2px solid white" }}
            allowFullScreen
            sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
            title="Licencias"
          ></iframe>
        </div>

        <Footer4 />
      </div>
    </>
  );
}

export default Licencias;
