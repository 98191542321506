import React from "react";
import Header2 from "../header2";
import Footer4 from "../footer4";
import TituloAnimado from "../tituloAnimado";
import { Link } from "react-router-dom";


function DatosEconomicos() {
    return (
        <div className="seccion2-container">
            <div className="header2-container">
                <p className="titular"> Datos Económicos </p>
                <Header2 />
            </div>

            <div className="seccion2-wrap">
                <div className="container texto2-seccion">
                    <TituloAnimado extraClass="titulo-secciones2">Transparencia Financiera</TituloAnimado>
                    <br /><br />
                    {/* Contenido de la página */}
                    <p>En San Isidro buscamos los más altos estándares de transparencia e integridad en la gestión municipal. El acceso a la información es una herramienta de participación democrática esencial para que los vecinos puedan evaluar la gestión municipal.</p>


                    <br /><br />

                    <div className="button-container titulo-secciones2">
                        <Link to="/sit.eco-fin.">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton cult "
                            style={{ height: '70px'}}>
                                Situación Económico-Financiera
                            </button>
                        </Link>
                        <Link to="/presupuestos">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton cult"
                               style={{ height: '70px'}}>
                                Presupuestos
                            </button>
                        </Link>
                        <Link to="/stock-deuda">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton cult"
                               style={{ height: '70px'}}>
                                Stock de deuda
                            </button>
                        </Link>
                        <Link to="/ejecucion-presup">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton cult"
                               style={{ height: '70px'}}>
                                Ejecución presupuestaria
                            </button>
                        </Link>

                        <a href="https://www.sanisidro.gob.ar/tramites-y-servicios/compras" target="_blank" rel="noopener noreferrer">
                            <button type="button" className="btn btn-outline-success  btn-lg link-boton cult "
                               style={{ height: '70px'}}>
                                Compras y licitaciones
                            </button>
                        </a>
                    </div>
                </div>
            </div>

            <Footer4 />
        </div>
    );
}

export default DatosEconomicos;
