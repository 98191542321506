import React, { useState, useEffect } from "react";


const MatrixRain = () => {
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    const numColumns = Math.floor(document.querySelector('.outer-container').offsetWidth / 20);
    const initialColumns = Array.from({ length: numColumns }, () => ({
      char: getRandomChar(),
      top: Math.random() * -100,
      speed: Math.random() * 5 + 2,
      resetCount: 0,
    }));
    setColumns(initialColumns);

    const interval = setInterval(() => {
      setColumns((prevColumns) =>
        prevColumns.map((column) => {
          if (column.resetCount < 3) {
            if (column.top >= 100) {
              return {
                ...column,
                top: Math.random() * -100,
                resetCount: column.resetCount + 1,
              };
            }
            return {
              ...column,
              top: column.top + column.speed,
            };
          }
          return column;
        })
      );
    }, 70);

    return () => clearInterval(interval);
  }, []);

  const getRandomChar = () => {
    const chars = "abcdefghijklmnopqrstuvwxyz0123456789";
    return chars.charAt(Math.floor(Math.random() * chars.length));
  };

  return (
    <div className="matrix-rain-container"> {/* Reemplaza el estilo en línea por la clase CSS */}
      {columns.map((column, index) => (
        <div
          key={index}
          className="matrix-rain-char" // Usa la clase CSS para las letras
          style={{
            top: `${column.top}%`,
            left: `${index * 20}px`,
          }}
        >
          {column.char}
        </div>
      ))}
    </div>
  );
};

export default MatrixRain;
